import React from 'react';
import useAction from '../../store/actions';

export default function ListViewPagination({offset, setOffset, rows, numberOfRecords}) {
	const sAction = useAction();
	const contentLoad = sAction.dsGet('config/contentLoad');

	const rightActive = rows?.length % numberOfRecords === 0 && !contentLoad;
	const leftActive = offset !== 0;

	return (
		<div className="listPagination">
			<div className="listPagination__recordRange">
				<span>{(offset + 1 || 1)}</span>
				<span> - </span>
				<span>{(offset + numberOfRecords) || 50}</span>
			</div>
			<div className="listPagination__page">
				{leftActive ?
					<span onClick={() => setOffset(0)} className="listPagination__page-icon iconfa-anglesLeft" /> :
					<span className="listPagination__page-empty" />
				}
				<span
					onClick={() => {leftActive ? setOffset(offset - numberOfRecords) : null;}}
					className={`listPagination__page-icon iconfa-angleLeft ${leftActive ? '' : 'listPagination__disabled' }`}
				/>
				<span className="listPagination__page-count">
					{((offset + numberOfRecords) / numberOfRecords) || 1}
				</span>
				<span
					onClick={() => {rightActive ? setOffset(offset + numberOfRecords) : null;}}
					className={`listPagination__page-icon iconfa-angleRight ${rightActive ? '' : 'listPagination__disabled' }`}
				/>
			</div>
		</div>
	);
}
