import React from 'react';
import useAction from '../../../store/actions';
import utils from '../../../utils';

export default function RelateFilter({column, value, setFilter, module, prefix}) {
	const sAction = useAction();

	const openRelatePopup = (module) => {
		sAction.openPopup('ListViewPopup', `LBL_${module.toUpperCase()}`, false, {module: module, selectedRecords: value || []}, (recordsId) => {
			setFilter(column.columnName, recordsId);
		});
	};

	const clearSelection = () => {
		setFilter(column.columnName, []);
	};

	return (
		<div className="relateFilter">
			<div className="relateFilter__box" onClick={() => openRelatePopup(module)}>
				<div className="relateFilter__text">{value?.length} - {utils.translate('LBL_SELECTED')}</div>
				<div className="relateFilter__icon iconfa-search" />
			</div>
			{value?.length > 0 ? <span className="relateFilter__icon iconfa-delete" onClick={clearSelection} /> : null}
		</div>
	);
}
