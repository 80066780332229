import React, { useEffect } from 'react';
import utils from '../../utils';

export default function ToastSlice({ title, description, type, index, timeoutId, closeSlice }) {
	useEffect(() => {
		return () => clearTimeout(timeoutId);
	}, []);

	const descriptionTranslate = utils.translate(description);

	return (
		<div className={`toastSlice toastSlice-${type}`}>
			<h4 className="toastSlice__title">{utils.translate(title)}</h4>
			{description ? <p className="toastSlice__description">{descriptionTranslate}</p> : null}
			<span className="iconfa-cross toastSlice__close" onClick={() => closeSlice(index)}></span>
		</div>
	);
}
