import React from 'react';
import { Link } from 'react-router-dom';
import utils from '../../../utils';
import useAction from '../../../store/actions';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	['& .MuiTooltip-tooltip']: {
		backgroundColor: 'grey',
		color: 'white',
		fontSize: 12,
		padding: '4px !important',
	},
});

export default function ListViewLink({ data, style, className, module, name, popupSelectCallback, recordName, disableMultiple, disabledRecords }) {
	const sAction = useAction();

	const isPopup = popupSelectCallback;
	const isName = name === 'name' || name === 'username';
	const isNotRelated = !disabledRecords?.includes(data.id.toString());

	const handleClick = (module, id) => {
		if (isPopup) {
			if (isNotRelated && isName) {
				if (disableMultiple) {
					popupSelectCallback({ name: recordName, id: id });
				} else {
					popupSelectCallback([id]);
				}
			} else {
				if (!isName) {
					window.open(`/${utils.getUrlPrefix()}/detail/${module}/${id}`, '_blank');
				}
			}
		} else {
			sAction.clearModuleData();
		}
	};

	const relatedLabel = utils.translate('LBL_ALREADY_RELATED');

	return (
		<td className={`${className} ListViewLink`} style={style}>
			{!isPopup ? (
				<Link
					className="ListViewLink__link"
					onClick={() => handleClick(data?.module, data?.id)}
					to={`/${utils.getUrlPrefix()}/detail/${data?.module}/${data?.id}`}
				>
					{data?.module !== module && data?.id != 0 ? <span className={`iconfa-${data?.module}`}></span> : null}
					{data?.value}
				</Link>
			) : (
				<p
					className={`ListViewLink__link ${!isNotRelated ? 'ListViewLink__link-disabled' : ''}`}
					onClick={() => handleClick(data?.module, data?.id)}
				>
					{data?.module !== module && data?.id != 0 ? <span className={`iconfa-${data?.module}`}></span> : null}
					{!isNotRelated ? (
						<CustomTooltip placement="top" title={relatedLabel}>
							<span>{data?.value}</span>
						</CustomTooltip>
					) : (
						data?.value
					)}
				</p>
			)}
		</td>
	);
}
