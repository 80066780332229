import React, { useState, useEffect, lazy, Suspense, useMemo } from 'react';
import useAction from '../../../../store/actions';
import ButtonBasic from '../../../Buttons/ButtonBasic';
import utils from '../../../../utils';
import LinesGroupTotal from './LinesGroupTotal';
import LinesGroupHeader from './LinesGroupHeader';
import getNewLines from './linesFunctions/getNewLines';
import useCalculateLines from './linesFunctions/useCalculateLines';
import { Skeleton } from '@mui/material';
import LineRow from './LineRow';

const LinesGroup = lazy(() => import('./LinesGroup'));

/**
 *
 * @export
 * @param {Object} viewData
 * @param {Object} viewData
 * @param {Boolean} isPopup
 *
 * @return {*}
 */
export default function Lines({
	viewData,
	prefix,
	isPopup,
	loadLines,
	setLoadLines,
}) {
	const sAction = useAction();
	const [allRows, setAllRows] = useState([]);
	const linesData = viewData?.detail?.linesData;
	const linesHaveGroups = viewData.module === 'quote';

	const currencies = sAction.dsGet('config/currencies');

	/***/
	const getDetailLines = () => {
		setLoadLines(true);
		utils
			.post('getDetailLines', {id: viewData.recordId, module: viewData.module})
			.then((res) => {
				sAction.initDetailLines(res.data.lines, prefix);
				setLoadLines(false);
			})
			.catch((error) => {
				console.error(error);
				setLoadLines(false);
			});
	};

	const initRecordId = isPopup ? viewData.recordId : utils.getRecordId();
	const initModuleName = isPopup ? viewData.module : utils.getModuleName();

	useEffect(() => {
		if (viewData.module === initModuleName && viewData.recordId === initRecordId) {
			getDetailLines();
		}
	}, [viewData.recordId, viewData.module]);

	useEffect(() => {
		if (linesHaveGroups) {
			/**
 			 * @return {Array}
 			*/
			const getAllLinesRows = () => {
				let allRows = [];
				linesData?.lines?.map((group) => {
					allRows = [...allRows, ...group.rows];
				});

				return allRows;
			};

			setAllRows(getAllLinesRows());
		} else {
			setAllRows(linesData?.lines);
		}
	}, [linesData?.lines]);

	const defaultGroup = {
		id: null,
		name: '',
		discount: viewData.detail?.fields?.discount?.value || 0,
		discountType: '%',
		note: '',
		rows: [],
	};

	/**
 	 * @return {Array}
 	*/
	const addNewGroup = () => {
		const oldLines = linesData.lines;
		const newLines = [...oldLines, defaultGroup];
		sAction.dsSet(`${prefix}/detail/linesData/lines`, newLines);
	};

	/**
	 * @param {Array} lines
	 *
 	 * @return {Array}
 	*/
	const initCalcLines = (lines) => {
		const newLines = lines;
		lines.forEach((currentLine, rowIndex) => {
			Object.keys(linesData.def).map((key) => {
				const lineDef = linesData.def[key];
				const taxRate = currentLine.taxRate;

				if (lineDef.formula) {
					let priceAfterDiscount =
            currentLine.discountType === '%'
            	? parseFloat(currentLine.sellingPrice) *
                ((100 - parseFloat(currentLine.discount)) / 100)
            	: parseFloat(currentLine.sellingPrice) -
                parseFloat(currentLine.discount);
					priceAfterDiscount = parseFloat(
						utils.roundNumber(
							priceAfterDiscount || currentLine.priceAfterDiscount || currentLine.sellingPrice
						)
					);
					const result = useCalculateLines(lineDef.formula, {
						...currentLine,
						priceAfterDiscount,
						taxRate,
					});

					newLines[rowIndex][key] = result;
				}
			});
		});

		return newLines;
	};

	/**
	 * @param {Number} groupIndex
	 *
 	 * @return {Array}
 	*/
	const addRow = (groupIndex) => {
		sAction.openPopup(
			'ListViewPopup',
			`LBL_${viewData.module.toUpperCase()}`,
			false,
			{ module: 'product', disableMultiple: true },
			(item) => {
				setTimeout(() => {
					sAction.openPopup(
						'AddDetailLine',
						'LBL_NEW_LINE_POPUP',
						false,
						{ recordId: item.id },
						(fetchData) => {
							const { amount, subProducts } = fetchData;
							sAction.load();
							const params = {
								module: 'product',
								recordId: item.id,
							};
							if (fetchData.variantId) {
								params.variantId = fetchData.variantId;
							}
							utils.post('getDataForLine', params).then((res) => {
								if (res.data.length > 0) {
									if (linesHaveGroups) {
										let newLines = getNewLines(
											!subProducts ? [res.data[0]] : res.data,
											linesData.def,
											linesData.lines[groupIndex].rows,
											amount,
											currencies,
											viewData.detail.fields.currency.value,
											parseFloat(viewData.detail.linesData?.lines?.[groupIndex]?.discount), // Group discount
										);
										newLines = initCalcLines(newLines, groupIndex);
										sAction.dsSet(`${prefix}/detail/linesData/lines/${groupIndex}/rows`, newLines);
									} else {
										let newLines = getNewLines(
											!subProducts ? [res.data[0]] : res.data,
											linesData.def,
											linesData.lines,
											amount,
											currencies,
											viewData.detail.fields.currency.value
										);
										newLines = initCalcLines(newLines, groupIndex);
										sAction.dsSet(`${prefix}/detail/linesData/lines`, newLines);
									}
								}
								sAction.unload();
							});
						}
					);
				});
			}
		);
	};

	/**
 	 * @return {JSX}
 	*/
	const renderGroups = () => {
		let groups = linesData.lines;
		if (groups.length === 0) {
			// Default first group
			groups = [defaultGroup];
			setTimeout(() => {
				sAction.dsSet(`${prefix}/detail/linesData/lines`, groups);
			});
		}

		return groups.map((group, i) => {
			return (
				<Suspense fallback={<Skeleton className="linesGroup__load" variant="rectangular" height={200} />} key={i}>
					<LinesGroup
						linesDef={linesData.def}
						prefix={prefix}
						index={i}
						group={group}
						linesData={linesData}
						module={viewData.module}
						linesHaveGroups={linesHaveGroups}
						addRow={addRow}
						allRows={allRows}
						addNewGroup={addNewGroup}
						initCalcLines={initCalcLines}
					/>
				</Suspense>
			);
		});
	};

	/**
 	 * @return {JSX}
 	*/
	const renderLinesTotal = () => {
		return Object.keys(linesData.def).map((key) => {
			return <td className="linesTotal__line" key={key} style={{ width: linesData.def[key]?.width }}></td>;
		});
	};

	/**
 	 * @return {JSX}
 	*/
	const renderLines = () => {
		return linesData?.lines?.map((row, i) => {
			return (
				<LineRow
					row={row}
					key={row.id}
					module={viewData.module}
					def={linesData.def}
					rows={linesData.lines}
					prefix={prefix}
					rowIndex={i}
					linesDef={linesData.def}
					linesHaveGroups={linesHaveGroups}
					initCalcLines={initCalcLines}
				/>
			);
		});
	};

	const labelAddRow = utils.translate('LBL_ADD_ROW');

	if (loadLines || loadLines) {
		return (
			<Skeleton className="linesGroup__load" variant="rectangular" height={200} />
		);
	}

	return (
		<div className={`lines ${!linesHaveGroups ? 'linesClassic' : ''}`}>
			{linesHaveGroups ? (
				renderGroups()
			) : (
				<div className="linesGroup__wrapper">
					<table className="linesGroup__table">
						<LinesGroupHeader def={linesData?.def} />
						<tbody>
							{!linesHaveGroups ? renderLines() : null}
							<tr>
								<td colSpan={4}>
									<ButtonBasic
										icon="addRecord"
										label={labelAddRow}
										className="linesGroup__addRow"
										transparent={true}
										onClick={() => addRow()}
									/>
								</td>
								<td></td>
							</tr>
							{!linesHaveGroups ? (
								<>
									<tr>
										<td className="linesRow__delete"></td>
										{renderLinesTotal()}
									</tr>
									<tr>
										<td colSpan={2}></td>
										<LinesGroupTotal rows={allRows} allLines={true} prefix={prefix} module={viewData.module} />
									</tr>
								</>
							) : null}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
}
