import React, { useState, useRef, useEffect } from 'react';
import UserMenu from './UserMenu';
import Notifications from '../Notifications/Notifications';
import utils from '../../utils';
import { Badge } from '@mui/material';

/**
 * @export
 * @param {Object} userData
 *
 * @return {JSX}
 */
export default function AppHeader({ userData }) {
	const [notification, setNotification] = useState(false);
	const [userMenu, setUserMenu] = useState(false);
	const [notifyNumber, setNotifyNumber] = useState(0);

	const userMenuIconRef = useRef(null);
	const notificationRef = useRef(null);

	/**
 	 * Function that fetch user notifications
     */
	const getNotificationCount = () => {
		utils.post('getNotificationCount', {module: 'notification'}).then((res) => {
			if (res.data > 0) {
				setNotifyNumber(res.data);
			}
		}).catch((error) => {
			console.error(error);
		});
	};

	useEffect(() => {
		getNotificationCount();
		setInterval(() => {
			getNotificationCount();
		}, 1000 * 30);
	}, []);

	useEffect(() => {
		/**
		 * Handles click on userMenu (closing and opening)
 		 * @param {Event} event
  		 *
 		 * @return {Function}
    	 */
		const handleUserMenuClick = (event) => {
			if (userMenuIconRef.current && userMenuIconRef.current.contains(event.target)) {
				setUserMenu(!userMenu);
			}
		};
		document.addEventListener('click', handleUserMenuClick);
		return () => {
			document.removeEventListener('click', handleUserMenuClick);
		};
	}, [userMenuIconRef]);

	useEffect(() => {
		/**
		 * Handles click on notifications (closing and opening)
 		 * @param {Event} event
  		 *
 		 * @return {Function}
    	 */
		const handleUserMenuClick = (event) => {
			if (notificationRef.current && notificationRef.current.contains(event.target)) {
				setNotification(!notification);
			}
		};
		document.addEventListener('click', handleUserMenuClick);
		return () => {
			document.removeEventListener('click', handleUserMenuClick);
		};
	}, [notificationRef]);

	/**
	 * Function that logout of user when admin use logAs function
  	 *
 	 * @return {void}
     */
	const logoutAsUser = () => {
		if (!userData?.loginAsToken) {
			return;
		}

		utils.post('logoutAsUser', {module: 'user', loginAsToken: userData?.loginAsToken})
			.then((res) => {
				if (res.data != 0) {
					window.location.reload();
				}
			}).catch((error) => {
				console.error(error);
			});
	};

	const logoutTranslate = utils.translate('LBL_LOGOUT_AS');

	return (
		<header className="appHeader">
			{userData.sysadmin === 1 ?
				<div className="appHeader__client">
					{utils.getUrlPrefix()}
				</div> : null}
			<div className="appHeader__box">
				<Badge
					sx={{
						'& .MuiBadge-badge': {
							backgroundColor: '#DC3545'
						}
					}}
					className="appHeader__badge"
					color="secondary" max={99}
					badgeContent={notifyNumber}
					ref={notificationRef}>
					<div
						className={`appHeader__item ${notification ? 'appHeader__item-active' : ''}`}
					>
						<div className="appHeader__item-iconBox">
							<span className="iconfa-bell"></span>
						</div>
						{notification ?
							<Notifications
								setNotifyNumber={setNotifyNumber}
								notifyNumber={notifyNumber}
								setNotification={setNotification}
								userData={userData}
								closeNotifications={setNotification}
							/> : null}
					</div>
				</Badge>
				<div className={`appHeader__item ${userMenu ? 'appHeader__item-active' : ''}`}>
					<div
						ref={userMenuIconRef}
						className="appHeader__item-iconBox userMenuIcon"
					>
						<span className="iconfa-user"></span>
					</div>
					{userMenu ? <UserMenu userData={userData} setUserMenu={setUserMenu} /> : null}
				</div>
				{userData?.loginAsToken ?
					<div onClick={() => logoutAsUser()} className="appHeader__item appHeader__itemLarge appHeader__logoutAs">
						<div>{logoutTranslate}</div>
						<span className="iconfa-logout"/>
					</div>
					: null}
			</div>
		</header>
	);
}