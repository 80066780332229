import React, {useState} from 'react';
import ButtonBasic from '../Buttons/ButtonBasic';
import {useNavigate} from 'react-router-dom';
import utils from '../../utils';
import useAction from '../../store/actions';
import saveDetailValidation from './disableValidation/saveDetailValidation';
import toast from 'react-hot-toast';

// List actions
import newRecord from '../../hooks/listButtons/newRecord';
import importList from '../../hooks/listButtons/importList';

// Detail actions
import cancelDetail from '../../hooks/detailButtons/cancelDetail';
import detailChanges from '../../hooks/detailButtons/detailChanges';
import setUserPassword from '../../hooks/detailButtons/setUserPassword';
import restorePassword from '../../hooks/detailButtons/restorePassword';
import createProductVariant from '../../hooks/detailButtons/createProductVariant';
import other from '../../hooks/common/other';
import createOrder from '../../hooks/detailButtons/createOrder';
import exportExternal from '../../hooks/detailButtons/exportExternal';
import cancelQuote from '../../hooks/detailButtons/cancelQuote';
import approveQuote from '../../hooks/detailButtons/approveQuote';
import logAsUser from '../../hooks/detailButtons/logAsUser';
import copyQuote from '../../hooks/detailButtons/copyQuote';

// Common
import print from '../../hooks/common/print';
import createNewEvent from '../../hooks/detailButtons/createNewEvent';
import deleteRecord from '../../hooks/common/deleteRecord';
import goUrl from '../../hooks/detailFieldButtons/goUrl';
import { Skeleton } from '@mui/material';

/**
 * @export
 * @param {Object} buttons
 * @param {JSX} children
 * @param {Object} viewData
 * @param {String} prefix
 * @param {Function} popupCallback
 * @param {Object} userData
 * @param {Boolean} load
 * @param {String} className
 * @param {Function} setLoadLines
 *
 * @return {JSX}
 */
export default function ActionButtons({
	buttons,
	children,
	viewData,
	prefix,
	popupCallback,
	userData,
	load,
	className,
	setLoadLines,
}) {
	const [disableButtons, setDisableButtons] = useState(false);
	const navigate = useNavigate();
	const sAction = useAction();

	const globalChanges = sAction.dsGet(`${prefix}/detail/changes`);
	const isPopup = prefix?.includes('popups/');
	const recordId = sAction.dsGet(`${prefix}/recordId`);
	const urlPrefix = utils.getUrlPrefix();

	const selectListArray = sAction.dsGet(`${prefix}/list/selected`);

	// Translates
	const labelSave = utils.translate('LBL_SAVE_SUCCESSFUL');
	const labelError = utils.translate('LBL_ERROR');
	const labelSaving = utils.translate('LBL_SAVING');

	/**
 	 * @param {Object} button
 	 *
 	 * @return {Boolean}
 	*/
	const checkDisabled = (button) => {
		switch (button.name) {
		case 'saveDetail':
			return saveDetailValidation(button, viewData);
		case 'deleteRecords':
			return button.name === 'deleteRecords' && selectListArray?.length === 0 ? true : button.disabled;
		default:
			return button.disabled;
		}
	};

	/**
 	 * @return {JSX}
 	*/
	const renderButtons = () => {
		if (!buttons) {
			return null;
		}
		return Object.keys(buttons)?.map((key, i) => {
			const button = buttons[key];
			if (button.name === 'divider') {
				return <div className="actionButtons__divider" key={button.name + i} />;
			}
			let disabled = button.disabled;
			disabled = checkDisabled(button);

			if (isPopup && button.name === 'cancelDetail') {
				return;
			}

			if (button.name === 'createNewEvent' && recordId === 'newRecord') {
				return;
			}

			return <ButtonBasic
				disabled={disabled || disableButtons}
				id={button.name}
				label={button.label}
				key={button.name}
				icon={button.icon}
				style={button.style}
				onClick={() => {
					if (disableButtons) {
						return;
					}
					if (button.name === 'saveDetail') {
						buttonActions[button.action]();
					} else {
						buttonActions[button.action](button);
					}
				}}
				hideLabelOnLowRes={true}
				onClickSendProps={button.name === 'saveDetail' ? false : true}
			/>;
		});
	};

	/***/
	const getDetail = () => {
		return utils.post('getDetail', {id: viewData.recordId, module: viewData.module})
			.then((res) => {
				if (res.data) {
					sAction.dsSet(`${prefix}/detail/buttons`, res.data.buttons);

					// Get lines
					if (viewData.module === 'order') {
						setLoadLines(true);
						utils.post('getDetailLines', {id: viewData.recordId, module: viewData.module})
							.then((res) => {
								sAction.initDetailLines(res.data.lines, prefix);
								setLoadLines(false);
							})
							.catch((error) => {
								console.error(error);
								setLoadLines(false);
							});
					}
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	/**
	 * Detail actions
	 *
 	 * @param {Object} propsChanges
	 * @param {Boolean} disableToast
 	 *
 	 * @return {Boolean}
 	*/
	const saveDetail = async (propsChanges, disableToast) => {
		if (!utils.validateRequiredFields(viewData.detail.fields)) {
			return;
		}
		setDisableButtons(true);
		const additionChanges = propsChanges || {};
		let detailResultPromise = null;
		const resultPromise = utils.post('saveDetail', {...globalChanges, ...additionChanges, module: viewData.module, recordId: viewData.recordId, lines: viewData.detail?.linesData?.lines}).then((res) => {
			if (res?.data < 0) {
				throw new Error(res.dataResult || labelError);
			}

			return res;
		});

		const results = {
			loading: labelSaving,
			success: labelSave,
			error: (error) => error.toString(),
		};

		if (!disableToast) {
			toast.promise(Promise.all([resultPromise, detailResultPromise]), results);
		}

		try {
			const res = await resultPromise;

			if (res.data > 0) {
				sAction.dsSet(`${prefix}/detail/changes`, {});
				if (isPopup) {
					popupCallback(res.data);
					sAction.closePopup();
				} else {
					if (viewData.module === 'user') {
						utils.post('getDetailSubpanels', {module: viewData.module, recordId}).then((res) => {
							sAction.initSubpanels(res.data.subpanels ,prefix);
						}).catch((error) => {
							console.error(error);
						});
					}
					detailResultPromise = getDetail();
					navigate(`/${urlPrefix}/detail/${viewData.module}/${res.data}`);
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			setDisableButtons(false);
		}
	};

	const module = viewData?.module;

	const buttonActions = {
		newRecord: newRecord(module),
		deleteRecord: deleteRecord(module, recordId, isPopup, viewData?.list?.data, viewData?.list?.selected, popupCallback),
		cancelDetail: cancelDetail(module),
		saveDetail,
		restorePassword: restorePassword(module, recordId),
		createNewEvent: createNewEvent(module, prefix, viewData?.detail?.fields),
		approveQuote: approveQuote(prefix, saveDetail),
		cancelQuote: cancelQuote(prefix, userData, saveDetail),
		print: (buttonData) => print(module, recordId, buttonData.name)(),
		other: (buttonData) => other(module, buttonData.name)(),
		createOrder: createOrder(module, recordId, prefix, saveDetail),
		detailChanges: detailChanges(module, recordId),
		setUserPassword: setUserPassword(recordId),
		createProductVariant: createProductVariant(module, recordId, prefix),
		exportExternal: (buttonData) => exportExternal(module, recordId, prefix, buttonData.name, saveDetail)(),
		goUrl: (buttonData) => goUrl(buttonData?.url)(),
		logAsUser: logAsUser(module, recordId),
		copyQuote: copyQuote(recordId),
		importList: importList(module),
	};

	return (
		<div className={`actionButtons ${className}`}>
			{children}
			{load ?
				<>
					<Skeleton height={50} className="actionButtons__skeleton" />
					<Skeleton height={50} className="actionButtons__skeleton" />
				</> :
				renderButtons()}
		</div>
	);
}
