import React, {useState, useEffect} from 'react';
import BasicCheckbox from '../FormElements/BasicCheckbox';
import ColumnSort from './ColumnSort';
import TextFilter from './ListViewFilterFields/TextFilter';
import SelectFilter from './ListViewFilterFields/SelectFilter';
import DatetimeFilter from './ListViewFilterFields/DatetimeFilter';
import CheckboxFilter from './ListViewFilterFields/CheckboxFilter';
import RelateFilter from './ListViewFilterFields/RelateFilter';
import DateFilter from './ListViewFilterFields/DateFilter';
import Tooltip from '../Tooltip/Tooltip';
import utils from '../../utils';

export default function ListViewHeader({
	headerColumns,
	selectAllRecords,
	checked,
	sort,
	setSort,
	globalTranslates,
	setFilters,
	filters,
	prefix,
	saveFilters,
	module,
	disableMultiple
}) {

	const [openFilters, setOpenFilters] = useState(false);
	const [headerChecked, setHeaderChecked] = useState(checked);

	useEffect(() => {
		setHeaderChecked(checked);
	}, [checked]);

	useEffect(() => {
		setOpenFilters(false);
	}, [module]);

	const setFilter = (fieldName, value) => {
		setFilters({...filters, [fieldName]: value});
	};

	const renderFilterField = (column) => {
		let columnValue = null;
		if (filters !== null) {
			columnValue = filters?.[column.columnName];
		}
		switch(column.fieldType) {
		case 'text':
		case 'email':
		case 'textarea':
			return <TextFilter setFilter={setFilter} value={columnValue || ''} column={column} />;
		case 'select':
			return <SelectFilter setFilter={setFilter} value={columnValue || []} column={column} />;
		case 'datetime':
			return <DatetimeFilter setFilter={setFilter} value={columnValue || {}} column={column} />;
		case 'date':
			return <DateFilter setFilter={setFilter} value={columnValue || {}} column={column} />;
		case 'checkbox':
			return <CheckboxFilter setFilter={setFilter} value={!columnValue && columnValue !== 0 ? -1 : columnValue} column={column} />;
		case 'relateSelect':
			return <RelateFilter  setFilter={setFilter} value={columnValue || ''} column={column} module={column?.module} prefix={prefix} />;
		default:
			return <TextFilter setFilter={setFilter} value={columnValue || ''} column={column} />;
		}
	};

	const renderHeader = () => {
		if (headerColumns?.length > 0) {
			const cells = headerColumns?.map((column) => {
				const width = window?.innerWidth >= 900 ? {width: column?.columnWidth} : {minWidth: column?.columnWidth};
				const filterValue = filters?.[column.columnName];
				return (
					<td key={column?.columnName} style={width} className={`listViewHeader__cell ${column?.hideOnLowRes ? 'hiddenOnLowRes' : ''}`}>
						<div className="listViewHeader__box">
							{openFilters ? renderFilterField(column) :
								<div className="listViewHeader__columnName">
									{filterValue != null && filterValue !== -1 && filterValue !== '' && (column.fieldType === 'select' && filterValue != 0) ?
										<span className="iconfa-filter" /> :
										null}
									{globalTranslates[column.label]}
								</div>}
							<ColumnSort setSort={setSort} sort={sort} columnName={column?.columnName} />
						</div>
					</td>
				);
			});
			return cells;
		}
	};

	return (
		<tr className="listViewHeader">
			<td className={headerColumns?.length ? 'listViewHeader__checkboxCell' : ''}>
				{headerColumns?.length !== 0 && !disableMultiple ? <BasicCheckbox size="small" color="blue" checked={headerChecked} onClick={() => selectAllRecords()} /> : null}
			</td>
			{renderHeader()}
			<td className="listViewHeader__filter">
				{headerColumns?.length !== 0 && <div className={!openFilters ? 'listViewHeader__filter-iconBox' : ''}>
					{openFilters ? 
						<Tooltip placement="top" title={'LBL_SAVE_FILTER'}>
							<div onClick={() => saveFilters()} className="listViewHeader__filter-icon iconfa-save" />
						</Tooltip>
						: null}
					<Tooltip placement="top" title={'LBL_FILTER_LIST'}>
						<div onClick={() => setOpenFilters(!openFilters)} className="listViewHeader__filter-icon iconfa-filter" />
					</Tooltip>
				</div>}
			</td>
		</tr>
	);
}
