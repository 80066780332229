import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import utils from '../../utils';
import useAction from '../../store/actions';
import SubMenuItem from './SubMenuItem';

/**
 *
 *
 * @export
 * @param {Object} menuModule
 * @param {Function} setMenuOpen
 * @param {String} module
 * @param {Boolean} leftMenuOpen
 * @param {String} color
 * @param {String} fontColor
 *
 * @return {JSX}
 */
export default function LeftMenuItem({menuModule, setMenuOpen, module, leftMenuOpen, color, fontColor}) {
	const sAction = useAction();
	const [open, setOpen] = useState(JSON.parse(localStorage.getItem('leftMenuState'))?.[menuModule.name] || false);

	/**
	 * Set left menu state into locale storage
	 *
	 * @param {String} name
	 * @param {Boolean} state
	 */
	const setLocalStorage = (name, state) => {
		const leftMenuState = JSON.parse(localStorage.getItem('leftMenuState')) || {};
		leftMenuState[name] = state;
		localStorage.setItem('leftMenuState', JSON.stringify(leftMenuState));
	};

	/**
	 * Handles click on item
	 *
	 * @returns {void}
	 */
	const handleClick = () => {
		if (!open) {
			if (menuModule.submenu) {
				sAction.dsSet('view/module', menuModule.submenu[0].name);
			} else {
				sAction.dsSet('view/module', menuModule.name);
			}
		}
		if (menuModule.submenu) {
			handleOpen(menuModule.name);
		}
		if (window.innerWidth <= 900) {
			setMenuOpen(false);
		}
	};

	/**
	 * @param {String} name
	 *
	 * @returns {void}
	 */
	const handleOpen = (name) => {
		setOpen(!open);
		setLocalStorage(name, !open);
	};

	/**
	 * Renders subitems
	 *
	 * @param {Array} submenu
	 *
	 * @returns {JSX}
	 */
	const renderSubMenu = (submenu) => {
		return submenu.map((item, i) => {
			return (
				<SubMenuItem leftMenuOpen={leftMenuOpen} index={i} key={item.name} data={item} setMenuOpen={setMenuOpen} module={module} fontColor={item.fontColor} />
			);
		});
	};

	let activeClass = utils.getModuleName() === menuModule?.name ? 'leftMenu__module-active' : '';
	if (module === 'home' && menuModule.name === 'home') {
		activeClass = 'leftMenu__module-active';
	}
	if (menuModule?.submenu?.length > 0) {
		menuModule.submenu.forEach(element => {
			if (element.name === utils.getModuleName()) {
				activeClass = 'leftMenu__module-active';
			}
		});
	}
	let redirectTo = menuModule.standardModule ? `/list/${menuModule?.name}` : `/${menuModule?.name}`;
	redirectTo = menuModule.submenu ? menuModule.submenu[0].standardModule ? `/list/${menuModule.submenu[0].name}` :  `/${menuModule.submenu[0].name}` : redirectTo;

	return (
		<>
			<Link onClick={() => handleClick(menuModule?.name)} to={ !open ? `${utils.getUrlPrefix()}${redirectTo}` : null} key={menuModule?.name} className={`leftMenu__module ${activeClass}`}>
				<span style={{color: color}} className={`leftMenu__module-icon iconfa-${menuModule?.icon}`}></span>
				<div style={{color: fontColor}} className="leftMenu__module-name">
					{utils.translate(menuModule.label)}
				</div>
				{menuModule.submenu ? <span className={`leftMenu__submenuIcon iconfa-angleUp ${open ? 'leftMenu__submenuIcon-open' : 'leftMenu__submenuIcon-close'}`}></span> : null}
			</Link>
			{menuModule.submenu && open ? renderSubMenu(menuModule.submenu) : null}
		</>
	);
}