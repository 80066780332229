import React, {useEffect, useState, Suspense} from 'react';
import utils from '../../utils';
import useAction from '../../store/actions';
import AppError from '../../pages/Error/AppError';
import ActionButtons from '../ActionButtons/ActionButtons';
import Lines from './DetailComponents/Lines/Lines';
import Subpanels from './DetailComponents/Subpanels/Subpanels';
import ProductVariant from './DetailComponents/ProductVariant/ProductVariant';
import {Skeleton} from '@mui/material';

const DetailForm = React.lazy(() => import('./DetailComponents/DetailForm/DetailForm'));

/**
 *
 *
 * @export
 * @param {Object} userData
 * @param {String} prefix
 * @param {Function} popupCallback
 * @param {Object} config
 * @param {Object} viewData
 *
 * @return {JSX}
 */
export default function Detail({userData, prefix, popupCallback, config, viewData}) {
	const [error, setError] = useState(false);
	const [load, setLoad] = useState(true);
	const [loadLines, setLoadLines] = useState(false);
	const sAction = useAction();

	const isPopup = prefix.includes('popup');

	const initRecordId = isPopup ? viewData.recordId : utils.getRecordId();
	const initModuleName = isPopup ? viewData.module : utils.getModuleName();


	// Existing record
	useEffect(() => {
		if (viewData.module === initModuleName && viewData.recordId === initRecordId) {
			getDetail();
		}
	}, [viewData.module, viewData.recordId]);

	/***/
	const getDetail = () => {
		if (!isPopup) {
			// sAction.contentLoad();
		}
		const sendRecordId = initRecordId === 'newRecord' ? null : initRecordId;
		utils
			.post('getDetail', {id: sendRecordId, module: initModuleName})
			.then((res) => {
				setLoad(false);
				// sAction.contentUnload();
				if (res.data && res.data.fields) {
					sAction.initDetail(
						res.data.def,
						res.data.fields,
						res.data.buttons,
						res.data.timeline,
						res.data.subpanels || [],
						prefix);
				} else {
					if (res.dataResult === 'No access') {
						setError('noAccess');
					} else {
						setError('recordNotExists');
					}
				}
			})
			.catch((error) => {
				setLoad(false);
				// sAction.contentUnload();
				console.error(error);
			});
	};

	const errorMessagesTranslates = {
		noAccess: {
			message: utils.translate('LBL_NO_ACCESS'),
			info: utils.translate('LBL_NO_ACCESS_MESSAGE'),
		},
		moduleNotExists: {
			message: utils.translate('LBL_MODULE_NOT_EXISTS'),
			info: utils.translate('LBL_MODULE_NOT_EXISTS_INFO'),
		},
		recordNotExists: {
			message: utils.translate('LBL_DETAIL_NOT_FOUND'),
			info: utils.translate('LBL_DETAIL_NOT_FOUND_MESSAGE'),
		}
	};

	if (error) {
		return <AppError errorMessage={errorMessagesTranslates[error]?.message} infoMessage={errorMessagesTranslates[error]?.info} />;
	}

	return (
		<>
			{isPopup && load && !config?.load ?
				<div className="loadBox"></div>
				:
				<>
					<div className="actionBox">
						<ActionButtons
							load={load}
							popupCallback={popupCallback}
							userData={userData}
							prefix={prefix}
							viewData={viewData}
							buttons={viewData?.detail?.buttons}
							setLoadLines={setLoadLines}
						/>
					</div>
					<div className="detail">
						<div className="detail__content">
							{Object.keys(viewData?.detail?.fields)?.length > 0 ?
								<Suspense fallback={<Skeleton variant="rectangular" height={400} />}>
									<DetailForm
										prefix={prefix}
										userData={userData}
										viewData={viewData}
										fields={viewData.detail.fields}
										recordId={viewData.recordId}
										module={viewData.module}
									/>
								</Suspense>
								: load ? <Skeleton variant="rectangular" height={400} /> : null}
							{viewData.module === 'quote' || viewData.module === 'order' ?
								<Lines
									prefix={prefix}
									viewData={viewData}
									isPopup={isPopup}
									loadLines={loadLines}
									setLoadLines={setLoadLines}
								/>
								: null}
							{/*It is loaded independently of the detail*/}
							{viewData?.detail?.subpanels && Object.keys(viewData?.detail?.subpanels)?.length > 0 && viewData.recordId &&
							<Subpanels
								prefix={prefix}
								userData={userData}
								viewData={viewData}
								recordId={viewData.recordId}
								module={viewData.module}
							/>
							}
							{/*It is loaded independently of the detail*/}
							{viewData.recordId && viewData.module === 'product' && !load &&
							<ProductVariant
								prefix={prefix}
								userData={userData}
								viewData={viewData}
								fields={viewData.detail.fields}
								recordId={viewData.recordId}
								module={viewData.module}
							/>
							}
						</div>
					</div>
				</>
			}
		</>
	);
}
