import React, {useEffect, useState} from 'react';
import ActionButtons from '../../components/ActionButtons/ActionButtons';
import utils from '../../utils';
import Tree from 'react-d3-tree';
import UserTreeNode from './UserTreeNode';

export default function UsersTree({globalConfig}) {
	const [userTree, setUserTree] = useState([]);
	const [translate, setTranslate] = useState({x: 0, y: 0});
	const [config, setConfig] = useState(globalConfig);

	useEffect(() => {
		setConfig(globalConfig);
	}, [globalConfig]);

	const systemSettings = config.systemSettings;

	useEffect(() => {
		// Fetch the user tree data
		utils
			.post('getUsersTree', {module: 'users_tree'})
			.then((res) => {
				const wrappedUserTreeData = [
					{
						name: systemSettings.companyName,
						attributes: {name: systemSettings.companyName, id: 'company'},
						children: res.data,
					},
				];
				setUserTree(wrappedUserTreeData);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	useEffect(() => {
		// Set initial translate values to center the tree
		const handleResize = () => {
			setTranslate({
				x: (window.innerWidth - 1200) / 2,
				y: 600,
			});
		};

		// Set the initial translation
		handleResize();

		// Update translation on window resize
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [userTree]);

	const renderCustomNode = (node) => {
		return <UserTreeNode config={globalConfig} node={node} />;
	};

	return (
		<>
			<div className="actionBox">
				<ActionButtons />
			</div>
			<div className="usersTree">
				{userTree.length > 0 && (
					<Tree
						data={userTree}
						allowForeignObjects={true}
						renderCustomNodeElement={(node) => renderCustomNode(node)}
						orientation="horizontal"
						pathFunc="step"
						translate={translate}
						scaleExtent={{min: 0.1, max: 3}}
						zoom={1}
						separation={{siblings: 1, nonSiblings: 1}}
						nodeSize={{x: 300, y: 100}} // Increase the x value for more horizontal spacing
					/>
				)}
			</div>
		</>
	);
}
