import React from 'react';
import ListViewLink from './ListViewFields/ListViewLink';
import ListViewEnum from './ListViewFields/ListViewEnum';
import ListViewNumber from './ListViewFields/ListViewNumber';
import ListViewFileLink from './ListViewFields/ListViewFileLink';
import ListViewEditableNumber from './ListViewFields/ListViewEditableNumber';
import ListViewTextHtml from './ListViewFields/ListViewTextHtml';
import ListViewCheckbox from './ListViewFields/ListViewCheckbox';

export default function ListViewField({
	fieldData, // Most of the time it is value but it is like fileLink we ned some data so it is object
	headerColumn,
	module,
	rowId,
	name,
	popupSelectCallback,
	recordName,
	parentId,
	disableMultiple,
	variantId,
	disabledRecords,
	prefix,
}) {
	const columnWidth = headerColumn?.columnWidth;
	const width = window.innerWidth >= 900 ? {width: columnWidth} : {minWidth: columnWidth};
	const style = {...width, ...headerColumn?.style};

	switch (headerColumn?.fieldType) {
	case 'link':
	case 'relateSelect':
		return (
			<ListViewLink
				className={`listView__cell ${headerColumn?.hideOnLowRes ? 'hiddenOnLowRes' : ''}`}
				key={`${rowId}_${headerColumn?.columnName}_${module}`}
				data={fieldData}
				style={style}
				module={module}
				popupSelectCallback={popupSelectCallback}
				disableMultiple={disableMultiple}
				name={name}
				recordName={recordName}
				disabledRecords={disabledRecords}
			/>
		);
	case 'select':
		return (
			<ListViewEnum
				className={`listView__cell ${headerColumn?.hideOnLowRes ? 'hiddenOnLowRes' : ''}`}
				value={fieldData}
				name={name}
				options={headerColumn?.options}
				prefix={prefix}
				style={style}
			/>
		);
	case 'number':
	case 'float':
	case 'currency':
		return (
			<ListViewNumber
				className={`listView__cell ${headerColumn?.fieldType} ${headerColumn?.hideOnLowRes ? 'hiddenOnLowRes' : ''}`}
				value={fieldData}
				style={style}
			/>
		);
	case 'fileLink':
		return (
			<ListViewFileLink
				className={`listView__cell ${headerColumn?.hideOnLowRes ? 'hiddenOnLowRes' : ''}`}
				path={fieldData?.value}
				recordId={rowId}
				module={module}
				recordName={recordName}
				style={style}
			/>
		);
	case 'checkbox':
		return (
			<ListViewCheckbox
				className={`listView__cell ${headerColumn?.fieldType} ${headerColumn?.hideOnLowRes ? 'hiddenOnLowRes' : ''}`}
				value={fieldData}
				style={style}
			/>
		);
	case 'editableNumber':
		return (
			<ListViewEditableNumber
				className={`listView__cell ${headerColumn?.hideOnLowRes ? 'hiddenOnLowRes' : ''}`}
				value={fieldData}
				id={rowId}
				parentId={parentId}
				module={module}
				variantId={variantId}
				// style={style}
			/>
		);
	case 'textHtml':
		return (
			<ListViewTextHtml
				className={`listView__cell ${headerColumn?.hideOnLowRes ? 'hiddenOnLowRes' : ''}`}
				value={fieldData}
				style={style}
			/>
		);
	default:
		return (
			<td key={`${rowId}_${headerColumn?.columnName}_${module}`} style={style} className={`listView__cell ${headerColumn?.hideOnLowRes ? 'hiddenOnLowRes' : ''}`}>
				{typeof fieldData === 'object' ? fieldData?.value : fieldData}
			</td>
		);
	}

}
