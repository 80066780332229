/**
 * Function redirects to given path and hard reload the page
 * @export
 * @param {string} path
 */
export default function hardRedirect(path) {
	const clientUrl = window.location.pathname.split('/')[1];
	window.location.pathname = `${clientUrl}${path}`;
	setTimeout(() => {
		window.location.reload(true);
	}, 100);
}