import React from 'react';
import { Button } from '@mui/material';
import utils from '../../utils';
import Tooltip from '../Tooltip/Tooltip';

/**
 * Basic button use at top of the modules
 *
 * @export
 * @param {String} label
 * @param {String} icon
 * @param {Object} style
 * @param {Function} onClick
 * @param {Boolean} disabled
 * @param {JSX} children
 * @param {Number | String} id
 * @param {String} className
 * @param {Boolean} hideLabelOnLowRes
 * @param {Boolean} onClickSendProps
 * @param {Boolean} transparent
 * @param {String} tooltip
 *
 * @return {JSX}
 */
export default function ButtonBasic({
	label,
	icon,
	style,
	onClick,
	disabled,
	children,
	id,
	className,
	hideLabelOnLowRes,
	onClickSendProps,
	transparent,
	tooltip,
}) {
	let finalClassName = `${className} buttonBasic`;
	finalClassName = style ? finalClassName = finalClassName + ' buttonBasic__' + style : finalClassName + ' buttonBasic__basic ';
	finalClassName = disabled ? finalClassName = finalClassName + ' buttonBasic__disabled' : finalClassName;
	finalClassName = hideLabelOnLowRes ? finalClassName = finalClassName + ' buttonBasic__hideLabel' : finalClassName;
	finalClassName = transparent ? finalClassName = finalClassName + ' buttonBasic__transparent' : finalClassName;

	return (
		<Tooltip placement="top" title={tooltip} >
			<Button id={id} onClick={(e) => onClickSendProps ? onClick(e) : onClick()} disabled={disabled} className={finalClassName}>
				{icon ? <span className={`buttonBasic__icon iconfa-${icon} buttonBasic__iconfa-${label ? 'margin' : ''}`}></span> : null}
				{label ?
					<span className="buttonBasic__content">
						{utils.translate(label)}
						{children}
					</span> : null}
			</Button>
		</Tooltip>
	);
}