import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import utils from '../../utils';
import useAction from '../../store/actions';

export default function FiltersSelect({savedFilters, selectedFilter, setSelectedFilter, setFilters, setSavedFilters}) {
	const debugMode = localStorage.getItem('debugMode') ? true : false;
	const sAction = useAction();

	const deleteItem = (id) => {
		sAction.confirmPopup('LBL_CONFIRM_DELETE_FILTER', () => {
			utils.post('deleteFilter', {id}).then((res) => {
				const newFilters = savedFilters.filter((obj) => obj.id !== id);
				setSavedFilters(newFilters);
				if (id == selectedFilter) {
					setSelectedFilter(0);
					setFilters({});
				}
			});
		});
	};

	const handleChange = (e, value) => {
		if (value === 0) {
			setFilters({});
			setSelectedFilter(value);
		} else {
			if (e.target.classList?.value === 'filtersSelect__item-name') {
				const foundObject = savedFilters.find(obj => obj.id === value);
				setFilters(foundObject.setValue);
				setSelectedFilter(value);
			}
		}
	};

	const renderOptions = () => {
		return savedFilters.map((filter) => {
			return (
				<MenuItem
					key={filter.id}
					value={filter.id}
					className="filtersSelect__item"
				>
					<span className="filtersSelect__item-name" onClick={(e) => handleChange(e, filter.id)}>
						{debugMode ? `${filter.name} [${filter.id}]`  : filter.name}
					</span>
					<span
						className="iconfa-delete filtersSelect__item-delete"
						onClick={(e) => deleteItem(filter.id)}
					></span>
				</MenuItem>
			);
		});
	};

	return (
		<FormControl className="filtersSelect classicInput">
			<Select
				value={selectedFilter}
			>
				<MenuItem className="filtersSelect__item" value={0}>
					<span className="filtersSelect__item-name" onClick={(e) => handleChange(e, 0)}>
						{debugMode ? `${utils.translate('LBL_ALL')} [0]` : utils.translate('LBL_ALL')}
					</span>
				</MenuItem>
				{renderOptions()}
			</Select>
		</FormControl>
	);
}
