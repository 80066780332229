import React from 'react';

export default function AppError({errorMessage, infoMessage, icon}) {
	return (
		<div className="appError">
			<div className="appError__box">
				<span className={icon ? `iconfa-${icon}` : 'iconfa-cancel-circle'}></span>
				<p>{errorMessage}</p>
			</div>
			<div className="appError__info">
				<div className="appError__line"></div>
				<p className="appError__message">{infoMessage}</p>
			</div>
		</div>
	);
}