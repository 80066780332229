import React, { useState, useEffect } from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import Tooltip from '../Tooltip/Tooltip';

export default function BasicCheckbox({
	checked,
	disabled,
	onClick,
	label,
	color,
	size,
	info,
}) {
	const [value, setValue] = useState(false);

	useEffect(() => {
		setValue(!!checked);
	}, [checked]);

	const handleClick = (e) => {
		e.target.checked = !value;
		setValue(!value);
		onClick(e);
	};

	let additionalClass = color ? `basicCheckbox__checkbox-${color} ` : 'basicCheckbox__checkbox-standardColor ';
	additionalClass = additionalClass + (size ? `basicCheckbox__checkbox-${size} ` : 'basicCheckbox__checkbox-standardSize ');

	return (
		<>
			{label ? <div className="detailField__label"></div> : null}
			<div className="basicCheckbox" onClick={onClick ? (e) => handleClick(e) : null}>
				<MuiCheckbox className={`${additionalClass}`} checked={value} disabled={disabled} />
				{label ? <label className="basicCheckbox__label">{label}
					{info ?
						<Tooltip placement="top" title={info}>
							<span className="fieldIcon iconfa-info"/>
						</Tooltip> : null}</label> : null}
			</div>
		</>
	);
}
