/**
 * @export
 * @param {Object} def
 * @param {Object} fields
 * @param {Object} buttons
 * @param {Object} timeline
 * @param {Object} subpanels
 * @param {String} prefix
 *
 * @return {Function}
 */
export default function initData(def, fields, buttons, timeline, subpanels, prefix) {
	return (dispatch) => {
		dispatch({
			type: 'INIT_DETAIL',
			payload: {def, fields, buttons, timeline, subpanels, prefix},
		});
	};
}
