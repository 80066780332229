import React, { useState, useEffect, useMemo } from 'react';
import { TextField } from '@mui/material';
import Tooltip from '../Tooltip/Tooltip';
import {default as MuiTooltip} from '@mui/material/Tooltip';

export default function Input({
	label,
	readonly,
	value,
	placeholder,
	required,
	fieldLength,
	onChange,
	error,
	type,
	fieldName,
	onBlur,
	className,
	defaultValue,
	info,
}) {
	const [inputValue, setInputValue] = useState(value || defaultValue || '');

	useEffect(() => {
		setInputValue(value || defaultValue || '');
	}, [value, defaultValue]);

	const handleChange = (e) => {
		if (!e.target.value) {
			setInputValue('');
		} else {
			setInputValue(e.target.value);
		}

		if (onChange) {
			onChange(e);
		}
	};

	const computedInputValue = (newValue) => {
		if (type === 'currency') {
			newValue = !newValue ? '0.00' : Number.parseFloat(newValue).toFixed(2);
		} else if (type === 'float') {
			newValue = !newValue ? '0.0000' : Number.parseFloat(newValue).toFixed(4);
		} else if (type === 'number') {
			newValue = !newValue ? '0' : Number.parseFloat(newValue).toFixed(0);
		}

		return newValue;
	};

	const debugMode = localStorage.getItem('debugMode') ? true : false;

	return (
		<div className={`inputFieldBox ${className}`}>
			{label && (
				<label className="detailField__label">
					{label}
					{debugMode ? <span className="debugName"> {fieldName}</span> : null}
					{info ?
						<Tooltip placement="top" title={info}>
							<span className="fieldIcon iconfa-info"/>
						</Tooltip> : null}
					{required ? <span className="detailField__required">*</span> : null}
				</label>
			)}
			<TextField
				error={!!error}
				required={required}
				placeholder={placeholder}
				className={`inputField ${readonly ? 'inputField__readonly' : ''} ${error ? 'inputField__error' : ''}`}
				onChange={handleChange}
				value={inputValue}
				name={fieldName}
				type={type === 'float' || type === 'currency' ? 'number' : type}
				disabled={readonly}
				onBlur={onBlur ? (e) => {
					e.target.value = computedInputValue(e.target.value);
					onBlur(e);
				} : null}
				inputProps={{
					readOnly: readonly,
					maxLength: fieldLength,
				}}
			/>
			{error ? (
				<MuiTooltip arrow title={<p className="detailToolTip">{error}</p>} placement="top">
					<span className="iconfa-warning inputFieldBox__error-icon"></span>
				</MuiTooltip>
			) : null}
		</div>
	);
}