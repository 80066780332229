import React from 'react';
import { Link } from 'react-router-dom';
import useAction from '../../store/actions';
import utils from '../../utils';

export default function UserTreeNode({ node, config }) {
	const sAction = useAction();
	const nodeData = node?.hierarchyPointNode?.data?.attributes;

	const hexToRgb = (hex) => {
		hex = hex.replace('#', '');
		let bigint = parseInt(hex, 16);
		let r = (bigint >> 16) & 255;
		let g = (bigint >> 8) & 255;
		let b = bigint & 255;
		return `${r}, ${g}, ${b}`;
	};

	let color = '#860700';

	if (nodeData.roleName === 'Klient') {
		color = '#28A228';
	} else if (nodeData.roleName === 'Obchodník') {
		color = '#1a6794';
	}

	const handleClick = () => {
		sAction.dsSet('view/module', 'user');
	};

	// Company parent node
	if (nodeData.id === 'company') {
		return (
			<g className="usersTreeNode">
				<foreignObject  width="300" height="75" x="-260" y="-37">
					<div className="usersTreeNode__container usersTreeNode__container-company" style={{border: '1px solid #333333'}}>
						<div style={{backgroundColor: '#333333'}} className="usersTreeNode__stripe"/>
						<div className="usersTreeNode__company">
							<div className="usersTreeNode__companyName">{config?.systemSettings?.companyName}</div>
							<div className="usersTreeNode__companyIcon fa-solid fa-building" />
						</div>
					</div>
				</foreignObject>
			</g>
		);
	}

	return (
		<g className="usersTreeNode">
			<foreignObject width="300" height="75" x="-260" y="-37">
				<div className="usersTreeNode__container" style={{border: `1px solid ${color}`}}>
					<div style={{backgroundColor: color}} className="usersTreeNode__stripe"/>
					<div className="usersTreeNode__content">
						<Link className="usersTreeNode__name" onClick={handleClick} to={`/${utils.getUrlPrefix()}/detail/user/${nodeData.id}`}>
							<div><span className="iconfa-user"/>{nodeData.name}</div>
						</Link>
						{nodeData.photo ?
							<div className="usersTreeNode__photoBox">
								<img className="usersTreeNode__photo" src={nodeData.photo} />
							</div> :
							<div className="usersTreeNode__icon iconfa-user"/>}
						<div style={{backgroundColor: `rgba(${hexToRgb(color)}, 0.75)`, border: `1px solid ${color}`}} className="usersTreeNode__role">{nodeData.roleName}</div>
						<div className="usersTreeNode__contact">
							{nodeData.email &&
							<div className="usersTreeNode__contact-box">
								<span className="usersTreeNode__contact-icon iconfa-email"/>
								<a className="usersTreeNode__contact-phone" href={'mailto:' + nodeData.email}>
									{nodeData.email}
								</a>
							</div>}
							{nodeData.phone &&
							<div className="usersTreeNode__contact-box">
								<span className="usersTreeNode__contact-icon iconfa-phone"/>
								<a className="usersTreeNode__contact-phone" href={'tel:+' + nodeData.phone}>
									{'+' + nodeData.phone.replace('-', ' ')}
								</a>
							</div>}
						</div>
					</div>
				</div>
			</foreignObject>
		</g>
	);
}
