import React from 'react';
import utils from '../../utils';
import useAction from '../../store/actions';

/**
 * @export
 * @param {Object} info
 * @param {Function} getCalendar
 * @param {String} calType month, week or day
 * @param {Date} date
 *
 * @return {JSX}
 */
export default function CalendarEventList({info, getCalendar, calType, date}) {
	const sAction = useAction();
	const eventData = info.event._def;
	const extendedProps = eventData.extendedProps;

	/**
	 * When is clicked on event popup with detail will appear
 	 *
 	 * @return {void}
 	 */
	const handleClick = () => {
		utils
			.post('getDetail', { id: extendedProps.recordId, module: 'event' })
			.then((res) => {
				const setFields = {};
				Object.keys(res.data.fields).forEach((fieldName) => {
					const field = res.data.fields[fieldName];
					setFields[fieldName] = field.value;
				});
				sAction.setPredefinedFields(setFields);
				sAction.openPopup(
					'DetailFormPopup',
					'LBL_EVENT',
					false,
					{ module: 'event', recordId: extendedProps.recordId },
					() => {
						getCalendar(calType, date);
					}
				);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<div className="calendarEventList">
			<div onClick={() => handleClick()} className="calendarEventList__header">
				<span className="iconfa-event calendarEventList__icon"></span>
				{eventData.title}
			</div>
			<div>{extendedProps.note}</div>
		</div>
	);
}