import React, {useState} from 'react';
import utils from '../../utils';
import Tooltip from '../Tooltip/Tooltip';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import useAction from '../../store/actions';

export default function NotificationItem({
	id,
	dateCreated,
	createdByUsername,
	message,
	title,
	type,
	status,
	notifyNumber,
	setNotifyNumber,
	setCount,
	count,
	parentModule,
	parentId,
	parentName,
	closeNotifications,
}) {
	const [open, setOpen] = useState(false);
	const [itemStatus, setItemStatus] = useState(status);
	const sAction = useAction();

	const timeSinceCreation = (dateString) => {
		const now = new Date();
		const createdDate = new Date(dateString.split('.').reverse().join(' '));
		const diffInSeconds = Math.floor((now - createdDate) / 1000);

		const secondsInMinute = 60;
		const secondsInHour = 3600;
		const secondsInDay = 86400;
		const secondsInWeek = 604800;
		const secondsInMonth = 2592000;
		const secondsInYear = 31536000;

		const minuteLabel = utils.translate('LBL_NOTIFY_DATE_MINUTE');
		const hourLabel = utils.translate('LBL_NOTIFY_DATE_HOUR');
		const dayLabel = utils.translate('LBL_NOTIFY_DATE_DAY');
		const weekLabel = utils.translate('LBL_NOTIFY_DATE_WEEK');
		const monthLabel = utils.translate('LBL_NOTIFY_DATE_MONTH');
		const yearLabel = utils.translate('LBL_NOTIFY_DATE_YEAR');

		if (diffInSeconds < secondsInHour) {
			const minutes = Math.floor(diffInSeconds / secondsInMinute);
			return `${minutes} ${minuteLabel}`;
		} else if (diffInSeconds < secondsInDay) {
			const hours = Math.floor(diffInSeconds / secondsInHour);
			return `${hours} ${hourLabel}`;
		} else if (diffInSeconds < secondsInWeek) {
			const days = Math.floor(diffInSeconds / secondsInDay);
			return `${days} ${dayLabel}`;
		} else if (diffInSeconds < secondsInMonth) {
			const weeks = Math.floor(diffInSeconds / secondsInWeek);
			return `${weeks} ${weekLabel}`;
		} else if (diffInSeconds < secondsInYear) {
			const months = Math.floor(diffInSeconds / secondsInMonth);
			return `${months} ${monthLabel}`;
		} else {
			const years = Math.floor(diffInSeconds / secondsInYear);
			return `${years} ${yearLabel}`;
		}
	};

	const labelArchLoad = utils.translate('LBL_ARCHIVE_LOADING');
	const labelArchSuccess = utils.translate('LBL_ARCHIVE_SUCCESS');
	const labelArchError = utils.translate('LBL_ARCHIVE_ERROR');

	const archive = async (e) => {
		e.stopPropagation();
		if (itemStatus === 'archived') {
			return;
		}

		const resultPromise = utils.post('changeNotificationStatus', {recordId: id, module: 'notification', status: 'archived'});

		toast.promise(resultPromise, {
			loading: labelArchLoad,
			success: labelArchSuccess,
			error: labelArchError,
		});

		try {
			const res = await resultPromise;
			if (res.data) {
				setCount({...count, archived: count.archived + 1, [itemStatus]: count[itemStatus] - 1});
				setItemStatus('archived');
			}
		} catch (error) {
			console.error(error);
		}
	};

	const markAsRead = () => {
		if (itemStatus === 'unread') {
			setItemStatus('read');

			utils.post('changeNotificationStatus', {recordId: id, module: 'notification', status: 'read'})
				.then((res) => {
					if (res.data) {
						setNotifyNumber(notifyNumber - 1);
						setCount({...count, read: count.read + 1, unread: count.unread - 1});
					}
				}).catch((error) => {
					console.error(error);
				});
		}

		setOpen(!open);
	};

	const goToLink = (e) => {
		e.stopPropagation();
		sAction.dsSet('view/module', parentModule);
		closeNotifications(false);
	};

	const archiveLabel = utils.translate('LBL_ARCHIVE');
	const systemLabel = utils.translate('LBL_SYSTEM');
	const translatedMessage = utils.translate(message);

	return (
		<div onClick={() => markAsRead()} className={`notificationItem ${open ? 'notificationItem__open' : ''}`}>
			<div className="notificationItem__header">
				<div className={`notificationItem__iconBox ${type}`}>
					<div className={`notificationItem__icon iconfa-${type}`}/>
				</div>
				<div className="notificationItem__main">
					<div className="notificationItem__title">{utils.translate(title)}
						<Link onClick={(e) => goToLink(e)} to={`${utils.getUrlPrefix()}/detail/${parentModule}/${parentId}`} className="notificationItem__link">
							<span className={`iconfa-${parentModule}`}/>
							{parentName}
						</Link>
					</div>
					<div className="notificationItem__byUserBox">
						<span className="notificationItem__user">{createdByUsername ? createdByUsername : systemLabel}</span>
						<span className="notificationItem__date">{dateCreated.slice(0, -3)}</span>
					</div>
				</div>
				<div className="notificationItem__time">
					{timeSinceCreation(dateCreated)}
				</div>
				{itemStatus !== 'archived' ?
					<Tooltip placement="top" title={archiveLabel}>
						<div onClick={(e) => archive(e)} className="notificationItem__archive iconfa-archive"/>
					</Tooltip>
					: null}
			</div>
			{open ? <div className="notificationItem__message">{translatedMessage}</div> : null}
		</div>
	);
}
