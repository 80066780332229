import React, {useState, useEffect} from 'react';
import Input from '../../FormElements/Input';
import utils from '../../../utils';
import Select from '../../FormElements/Select';
import Image from '../../FormElements/Image';
import useAction from '../../../store/actions';
import Phone from '../../FormElements/Phone';

/**
 * @export
 *
 * @param {Function} setValue
 * @param {Object} state state of the system config
 * @param {Function} setSaveDisabled set disable save button true/false
 *
 * @return {JSX}
 */
export default function Company({setValue, state, setSaveDisabled}) {
	const stateOptions = utils.getEnum('states');
	const sAction = useAction();
	const colors = window.colorPalette;
	const [emailError, setEmailError] = useState('');

	/**
	 * @returns {void}
	*/
	const handleImageDelete = () => {
		utils.post('deleteFile', {module: 'system', recordId: 'companyLogo'}).then((res) => {
			setValue('companyLogo', '');
		});
	};

	/**
	 * Handles change of the company logo
	 *
	 * @param {Event} e
	 *
	 * @returns {void}
	 */
	const handleImageChange = (e) => {
		e.preventDefault();
		sAction.load();

		const formData = {};
		formData.module = 'system';
		formData.file = e.target.files[0];
		formData.recordId = 'companyLogo';

		utils.post('uploadFile', formData).then((res) => {
			setValue('companyLogo', res.data?.value);
			sAction.dsSet('config/systemSettings/companyLogo', res.data?.value);
			sAction.unload();
		});
	};

	const invalidEmailLabel = utils.translate('LBL_INVALID_EMAIL');

	/**
	 * Handles change of the company logo
	 *
	 * @param {String} value
	 *
	 * @returns {void}
	 */
	const validateEmail = (value) => {
		if (!utils.validateEmail(value) && value !== '') {
			setEmailError(invalidEmailLabel);
			setSaveDisabled(true);
		} else if (value === '') {
			setEmailError('');
			setSaveDisabled(false);
		} else {
			setEmailError('');
			setSaveDisabled(false);
		}
	};

	useEffect(() => {
		if (state.email) {
			validateEmail(state.email);
		}
	}, []);

	return (
		<div className="config__form">
			<div style={{borderBottom: `2px solid ${colors[1]}`}} className="config__form-section">
				<span className="iconfas-company" />
				{utils.translate('LBL_COMPANY_BASIC_INFO')}
			</div>
			<Input
				label={utils.translate('LBL_COMPANY_NAME')}
				value={state.companyName}
				onChange={(e) => setValue('companyName', e.target.value)}
			/>
			<Input
				label={utils.translate('LBL_IC')}
				value={state.ic}
				onChange={(e) => setValue('ic', e.target.value)}
				fieldLength={8}
				fieldType="number"
			/>
			<Input
				label={utils.translate('LBL_DIC')}
				value={state.dic}
				onChange={(e) => setValue('dic', e.target.value)}
				fieldLength={12}
				fieldType="number"
			/>
			<div style={{borderBottom: `2px solid ${colors[0]}`}} className="config__form-section">
				<span className="iconfas-mapTarget"/>
				{utils.translate('LBL_COMPANY_ADDRESS')}
			</div>
			<Input
				label={utils.translate('LBL_STREET')}
				value={state.street}
				onChange={(e) => setValue('street', e.target.value)}
			/>
			<Input
				label={utils.translate('LBL_DESCRIPTIVE_NUMBER')}
				value={state.descriptiveNumber}
				onChange={(e) => setValue('descriptiveNumber', e.target.value)}
			/>
			<Input
				label={utils.translate('LBL_CITY')}
				value={state.city}
				onChange={(e) => setValue('city', e.target.value)}
			/>
			<Input
				label={utils.translate('LBL_ZIP')}
				value={state.zip}
				onChange={(e) => setValue('zip', e.target.value)}
				fieldLength={5}
			/>
			<Select
				onChange={(e) => setValue('state', e.target.value)}
				value={state.state}
				required={false}
				label={utils.translate('LBL_STATE')}
				fieldName="state"
				options={stateOptions}
			/>
			<div style={{borderBottom: `2px solid ${colors[2]}`}} className="config__form-section">
				<span className="iconfa-contact" />
				{utils.translate('LBL_CONTACT')}
			</div>
			<Phone
				onChange={(e) => setValue('phone', e.target.value)}
				fieldLength={9}
				label={utils.translate('LBL_PHONE')}
				value={state.phone}
			/>
			<Input
				label={utils.translate('LBL_EMAIL')}
				value={state.email}
				error={emailError}
				onChange={(e) => {
					setValue('email', e.target.value);
				}}
				onBlur={(e) => validateEmail(e.target.value)}
			/>
			<div style={{borderBottom: `2px solid ${colors[3]}`}} className="config__form-section">
				<span className="iconfas-bank" />
				{utils.translate('LBL_BANK_INFO')}
			</div>
			<Input
				label={utils.translate('LBL_BANK_NAME')}
				value={state.bank}
				onChange={(e) => setValue('bank', e.target.value)}
			/>
			<Input
				label={utils.translate('LBL_BANK_NUMBER')}
				value={state.bankAccountNumber}
				onChange={(e) => setValue('bankAccountNumber', e.target.value)}
			/>
			<Input
				label={utils.translate('LBL_BANK_IBAN')}
				value={state.iban}
				onChange={(e) => setValue('iban', e.target.value)}
			/>
			<Input
				label={utils.translate('LBL_BANK_SWIFT')}
				value={state.swift}
				onChange={(e) => setValue('swift', e.target.value)}
			/>
			<div style={{borderBottom: `2px solid ${colors[4]}`}} className="config__form-section">
				<span className="iconfa-others" />
				{utils.translate('LBL_REP_CAT_OTHERS')}
			</div>
			<Image
				onChange={(e) => handleImageChange(e)}
				allowDelete={true}
				allowUpload={true}
				label={utils.translate('LBL_COMPANY_LOGO')}
				module={'system'}
				fieldName={'companyLogo'}
				value={state.companyLogo}
				handleDelete={handleImageDelete}
				askDelete={true}
			/>
		</div>
	);
}
